import React, { createContext, useContext, useMemo, useReducer, useEffect, useState, useCallback } from 'react';
import AxiosService from "../../services/axios.service";
//import HomePage from "../../services/frontend/shop.service";
import { useRemember } from '@inertiajs/react'

const HomeContext = createContext(null);

export const HomeProvider: React.memo = ({ children, data  }) => {

  //console.log(props);

  const [cachePage, setCachePage] = useRemember({newArrivals:data.newArrivals.data, sliders:data.sliders.data, relatedData:data.relatedData,homePage:data.homepage});
  const [newArrivals, setNewArrivals] = useState(cachePage.newArrivals??[]);
  const [sliders, setSliders] = useState(cachePage.sliders??[]);
  const [homeData, setHomeData] = useState([]);
  const [relatedData, setRelatedData] = useState(cachePage.relatedData??[]);
  const [homePage, setHomePage] = useState(cachePage.homePage??[]);

  const contextValue = useMemo(() => {

    return { newArrivals, sliders, homePage,relatedData};


  }, [newArrivals]);

  

  useEffect(() => {

    setRelatedData(data.relatedData)
    setSliders(data.sliders.data);
    setHomePage(data.homepage)
    //setNewArrivals(data.newArrivals.data ?? [])
    setCachePage({
        homePage: homePage,
        relatedData: relatedData,
        sliders: sliders,
        newArrivals: newArrivals,

      });

    //console.log(newArrivals);
    initHomeData();
    //initHomeData1();

  }, []);

  async function initHomeData(){

    const response = await AxiosService({
      method:"get",
      url:'/api/home-data?new=1',

    })

    //console.log('response => ', response)

   
    //setRelatedData(response.data.relatedData)
    //setHomePage(response.data.homepage)
    setCachePage({
        homePage: homePage,
        relatedData: relatedData,
        sliders: response.data ? response.data.sliders : sliders,
        newArrivals: newArrivals,

    });
    setNewArrivals(response.data ? response.data.newArrivals : [])
  }


return (
    <HomeContext.Provider value={contextValue}>
      {/* <ContextDevTool context={AppContext} id="app-context" displayName="App" /> */}
      {children}
    </HomeContext.Provider>
  );

};

export const useHomeContext = () =>

  useContext<{
    newArrivals;
    sliders;
    homePage;
    relatedData;
    //setHomeData;

  }>(HomeContext);

export default HomeContext;
